*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    font-family: 'Roboto', sans-serif;
}

a{
    text-decoration: none;
    color: #000;
}

li{
    list-style: none;
}