.header_home{
    height: 60px;
    margin-top: 20px;
    border-bottom: 2px solid darken($gray, 16%);
    img{
        display: flex;
        margin: auto;
        height: 40px;
        align-items: center;
    }
}

.header_page{
    height: 60px;
    margin-top: 20px;
    i{
    position: absolute;
    color: darken($gray, 65%);
    top: 25px;
    font-size: 30px;
    margin-left: 30px;
    }
    img{
        display: flex;
        margin: auto;
        height: 40px;
        align-items: center;
    }
}