.functioning {
    padding: 50px;
    h2{
        display: flex;
        font-size: 2rem;
        justify-content: center;
        @include breakpoints(desktop){
            justify-content: flex-start;
            font-size: 1.8rem;
            margin-left: -2rem;
        }
    }
        &_list {
            display: flex;
            padding: 30px 0 0 0;
            @include breakpoints(desktop){
                flex-direction: column;
                align-items: center;
        }
    }
        &_tab{
            display: flex;
            background: $gray;
            border-radius: 2rem;
            padding: 25px;
            margin: 1rem;
            width: 100%;
            cursor: pointer;
            @include shadow;
            &:hover{
                background-color: lighten($primary, 32%);
                i{
                    color: $primary;
                }
        }
    }
        &_number{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            background: $primary;
            right: 40px;
            height: 30px;
            width: 30px;
            border-radius: 1rem;
    }
        &_text{
            position: relative;
            display: flex;
            align-items: center;
            right: 20px;
            font-size: 1.1rem;
            font-weight: 600;
            @include breakpoints(tablet){
                font-size: 1rem;
        }
    }
            i{
                margin-right: 1rem;
                color: darken($gray, 60%);
                font-size: 1.3rem;
        }
}