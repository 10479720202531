@mixin shadow($color: darken($gray, 25%)) {
    box-shadow: 0 3px 7px $color;
}

@mixin container {
    max-width: 1850px;
    margin: auto;
}

@mixin breakpoints($version) {
    @media screen and (max-width: map-get($breakpoints, $version)){
        @content;
    }
}

@mixin heart{
    .far.fa-heart {
        position: relative;
        cursor: pointer;
        font-size: 1.8rem;
        transition: color 250ms 50ms ease;
        &:hover{
            color: transparent;
        }
        &:hover > .fas.fa-heart{
            transform: scale(1);
        }
    }
    .fas.fa-heart{
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(170deg, $secondary, $primary);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        transform: scale(0);
        transition: transform 200ms 50ms ease-in-out;
    }
}

@mixin menu {
    @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
            animation-delay: 100ms * $i;
        }
    }
}