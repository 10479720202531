$primary:#9356DC;
$secondary:#FF79DA;
$green:#99E2D0;
$gray:#F5F5F5;
$white:#fff;
$black: #000;

$breakpoints:(
    tablet: 600px,
    desktop: 1024px
);