.restaurant {
    padding: 50px 0 50px 0;
    background: $gray;
    @include breakpoints(desktop){
        padding: 20px;
    }
        h2{
            display: flex;
            font-size: 1.9rem;
            justify-content: center;
            margin-bottom: 0.9rem;
            @include breakpoints(desktop){
                justify-content: flex-start;
                font-size: 1.8rem;
                margin-left: 1rem;
        }
    }
    &_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &_tab {
        position: relative;
        margin: 0.8rem;
        width: 47.5%;
        height: 100%;
        border-radius: 30px;
        @include shadow;
        @include breakpoints(desktop){
            width: 100%;  
        }
    }
    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 30px 30px 0 0;
        @include breakpoints(tablet){
            height: 150px;  
        }
    }
    &_tab--content{
        margin: 1rem 0 1rem 2rem;
        h3{
            font-weight: 700;
            margin-bottom: 0.5rem;
        }
        p{
            font-weight: 300;
            font-size: 1rem;
        }
        span{
            position: absolute;
            right: 15px;
            top: 15px;
            padding: 5px;
            background: $green;
            color: darken($green, 30%);
            border-radius: 1px;
            font-weight: 500;
            font-size: 0.9rem;
        }
    }
    &_tab--heart {
        position: absolute;
        right: 35px;
        bottom: 35px;
        @include heart;
        @include breakpoints(tablet){
            right: 20px;
            bottom: 35px; 
        }
    }
}
