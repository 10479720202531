.location {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: darken($gray, 4%);
    p{
        font-size: 1.3rem;
        font-weight: 600;
    }
    i {
        margin-right: 10px;
        font-size: 20px;
    }
}
