.btn{
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    height: 50px;
    padding: 0 50px;
    color: $white;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background: linear-gradient(170deg, $secondary, lighten($primary, 8%));
    border-radius: 30px;
    border: none;
    @include shadow;
    @include breakpoints(tablet){
        font-size: 0.9rem;
    }
    a{
        color: $white;
    }
    &--explore{
        padding: 15px 25px;
    }
    &:hover{
        transform: scale(1.1);
    }
}