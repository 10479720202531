.menu_img {
    img{
        width: 100%;
        height: 300px;
        object-fit: cover;
        @include breakpoints(desktop){
            height: 250px;
    }
    }
}

.menu_head {
    position: relative;
    border-radius: 2rem 2rem 0 0;
    padding: 30px 20px 50px;
    background-color: $gray;
    top: -30px;
    h2{
        font-family: 'Shrikhand', cursive;
        font-size: 1.8rem;
        font-weight: 500;
    }
    &--content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @include heart;
    }
}

.menu {
    display: flex;
    justify-content: space-between;
    @include breakpoints(desktop){
        flex-direction: column;
}
    &_list {
        width: 100%;
        .title {
            margin: 20px 0 20px 0;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 300;
            &::after{
                content: "";
                display: block;
                height: 3px;
                width: 40px;
                background-color: $green;
                margin-top: 5px;
            }
        }
    }
    &_tab {
        display: flex;
        position: relative;
        justify-content: space-between;
        background: $white;
        width: 95%;
        margin: 0 0 20px 0;
        border-radius: 1rem;
        overflow: hidden;
        height: 65px;
        cursor: pointer;
        animation: menu 1s;
        animation-fill-mode: both;
        @include menu;
        @include shadow;
        @include breakpoints(desktop){
            width: 100%;
    }
    }
    &_text {
        padding: 15px 0 15px 14px;
        justify-content: space-between;
        transform: scaleX(1);
        transform-origin: left;
        transition: width 250ms 100ms linear;
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: 5px;
        }
        p {
            margin: 0;
            font-weight: 300;
            font-size: 0.95rem;
        }
    }
    &_price {
        color: darken($gray, 75%);
        font-weight: 700;
        position: absolute;
        right: 25px;
        bottom: 15px;
        transform-origin: right;
        transition: transform 300ms 100ms linear;
    }
    .animation{
        display: flex;
        background-color: $green;
        width: 72px;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 300ms 150ms linear;
        .anim_icon{
            color: $white;
            font-size: 1.6rem;
            margin: auto;
            transform: rotate(0deg);
            transition: transform 500ms 100ms linear;
        }
    }
}
.menu_tab { 
    &:hover .menu_text{
        width: 50%;
        h3,p {
            animation: ellipse 0.8s forwards;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    &:hover .animation{
        transform: scaleX(1);
    }
    &:hover .anim_icon{
        transform: rotate(360deg);
    }
    &:hover .menu_price{
        transform: translateX(-70px);
    }
}

.com {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes menu {
   0% {
       transform: translateY(50%);
       opacity: 0;
   }
   100% {
       transform: translateX(0);
       opacity: 1;
   }
  }

  @keyframes ellipse {
    to {
        text-overflow: ellipsis;
    }
   }